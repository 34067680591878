import imag2 from "../Resources/2024/BSX/main.jpg";
import imag5 from "../Resources/2024/BSX/2b.jpg";
import imag6 from "../Resources/2024/BSX/3b.jpg";
import imag14 from "../Resources/2024/BSX/4b.jpg";
import imag15 from "../Resources/2024/BSX/5b.jpg";
import imag21 from "../Resources/2024/BSX/1b.JPG";
import imag22 from "../Resources/2024/BSX/6b.PNG";

//other
import imag7 from "../Resources/2022/SpaceWeek22_1.jpeg";
import imag9 from "../Resources/2022/Techno22_1.jpeg";

//gravitas photos 24
import imag31 from "../Resources/2024/Gravitas/2.JPG";
import imag32 from "../Resources/2024/Gravitas/3c.JPG";
import imag33 from "../Resources/2024/Gravitas/4.JPG";
import imag34 from "../Resources/2024/Gravitas/5.JPG";




const bsx = [
    {
      id: 1,
      src: imag2,
      alt: "ex2"
    },
    {
        id: 2,
        src: imag14,
        alt: "ex3"
    },
    {
      id: 2,
      src: imag5,
      alt: "ex3"
  }
  ,
    {
      id: 2,
      src: imag6,
      alt: "ex3"
  },
  {
    id: 3,
    src: imag22,
    alt: "ex"
}
,
  {
    id: 3,
    src: imag21,
    alt: "ex"
},
{
  id: 3,
  src: imag15,
  alt: "ex"
}


    
];
const spaceWeek = [
    {
      id: 1,
      src: imag7,
      alt: "ex2"
    }
];

const technoVit = [
    {
      id: 1,
      src: imag9,
      alt: "ex2"
    }
];

const gravitas = [
    {
      id: 1,
      src: imag31,
      alt: "ex2"
    },
    {
      id: 1,
      src: imag32,
      alt: "ex2"
    },
    {
      id: 1,
      src: imag33,
      alt: "ex2"
    },
    {
      id: 1,
      src: imag34,
      alt: "ex2"
    },
];

export { bsx, spaceWeek, technoVit, gravitas };